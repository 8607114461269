










































































































































































































































































































































































































































































import Tour from '@/dtos/tour';
import Vue from 'vue';
import { tomorrowIfSundayOrNextWeekend } from '@/utils/date_time';
import GpxParser from 'gpxparser';
import { router } from '@/router';
import Place from '@/dtos/place';
import Hint, { RideeHints } from '../components/Hint.vue';
import { loadGPX } from '@/store/modules/gpx';
import { defaultTourImageURL } from '@/defaults/defaults';
import EditorMode from '@/common/editor.mode';
import EditIcon from '@/assets/mobile/icons/edit-icon.svg';
import LocationField from '@/location/LocationField.vue';
import BaseFields from '@/add/base.fields';
import SharedFields from '@/add/shared.fields';

class Fields extends BaseFields {
  selectedSportType = 'Road';
  selectedDifficultyLevel = 'Intermediate';
  assemblyPoint = '';
  distance = 0;
  pace = 0;
  altitude = 0;
  sourceLink = '';
  gpx = null;
  routeStart: Place | null = null;
  legalDisclaimer = '';
}

export default Vue.extend({
  name: 'TourForm',
  components: { Hint, EditIcon, LocationField },
  props: {
    tourId: {
      type: String,
    },
    mode: {
      type: EditorMode,
      default() {
        return EditorMode.default;
      },
    },
    sharedFields: {
      type: SharedFields,
      required: false,
    },
  },
  data() {
    return {
      tour: null as Tour | null,
      fields: new Fields(this.sharedFields),
      headerImage: undefined as string | undefined,
      headerImagePreviewURL: undefined as string | undefined,
      defaultHeaderImageURL: undefined as string | undefined,
      gpxPreviewURL: null as string | null,
      deleteGPX: false,
      hasGPX: false,
      hintID: null as string | null,
    };
  },
  computed: {
    isSubmitable(): boolean {
      const f = this.fields;
      const fields = [
        f.title,
        f.selectedSportType,
        f.selectedDifficultyLevel,
        f.location.description,
      ];
      function notEmpty(str: string): boolean {
        return str.trim().length > 0;
      }
      return fields.every(notEmpty) && f.startDateTime > new Date();
    },
    buttonTitle(): string {
      if (this.mode.isEdit) return 'Tour aktualisieren';
      if (this.mode.isDuplicate) return 'Tour duplizieren';
      return 'Tour erstellen';
    },
  },
  async mounted() {
    if (this.mode.isCreate) {
      this.defaultHeaderImageURL = await defaultTourImageURL();
      this.headerImagePreviewURL = this.defaultHeaderImageURL;
    } else {
      this.tour = await this.$store.dispatch('tour/fetchTour', this.tourId);
      await this.$store.dispatch('tour/bindCurrentTour', this.tourId);
      this.populate();
    }
    this.hintID = RideeHints.descriptionLinks;
  },
  methods: {
    onLocationChange(p: Place) {
      this.fields.location = p;
    },
    updateHeaderImage(event: any) {
      const file = event.target.files[0];
      this.headerImage = file;
      this.headerImagePreviewURL = URL.createObjectURL(file);
    },
    attachGPX(event: any) {
      const file = event.target.files[0];
      this.fields.gpx = file;
      this.hasGPX = true;
      this.gpxPreviewURL = URL.createObjectURL(file);
      const form = this.fields;
      loadGPX(this.gpxPreviewURL, true).then((response) => {
        const gpx = new GpxParser();
        gpx.parse(response as string);
        const meta = gpx.metadata;
        const options = {
          text: 'Name und Kurzbeschreibung ersetzen?',
          width: '80%',
          confirmButtonColor: '#000000',
          confirmButtonText: 'Ja',
          cancelButtonText: 'Nein',
        };

        if ([form.title, form.description].find((field) => field)) {
          this.$confirm(undefined, undefined, undefined, options).then(() => {
            form.title = meta.name;
            form.description = meta.desc;
          });
        } else {
          form.title = meta.name;
          form.description = meta.desc;
        }
        const track = gpx.tracks[0];
        form.sourceLink = track.link.href || '';
        form.distance = Math.round(track.distance.total / 10) / 100;
        form.altitude = Math.round(track.elevation.pos);
        const start = track.points[0];
        form.routeStart = new Place(start.lat, start.lon, '');
      });
    },
    detachGPX() {
      this.deleteGPX = true;
      this.fields.gpx = null;
      this.fields.routeStart = null;
      this.hasGPX = false;
    },
    submit() {
      if (this.mode.isEdit) {
        const tour = this.tour;
        if (!tour) return;
        const { latitude, longitude, description } = this.fields.location;
        const newTour = new Tour({
          id: tour.id,
          headerImageURL: tour.headerImageURL,
          title: this.fields.title,
          description: this.fields.description,
          members: tour.members,
          sportType: this.fields.selectedSportType,
          difficultyLevel: this.fields.selectedDifficultyLevel,
          startDateTime: this.fields.startDateTime,
          location: this.fields.location,
          assemblyPoint: this.fields.assemblyPoint,
          distance: this.fields.distance,
          sourceLink: this.fields.sourceLink,
          gpxURL: tour.gpxURL || null /*backward compatibility*/,
          routeStart: this.fields.routeStart || null,
          pace: this.fields.pace,
          altitude: this.fields.altitude,
          author: tour.author,
          organizer: tour.organizer,
          memberCount: tour.memberCount,
          reactionCount: tour.reactionCount,
          maxMemberCount: tour.maxMemberCount,
          tags: tour.tags,
          legalDisclaimer: this.fields.legalDisclaimer,
        });
        this.$store
          .dispatch('tour/updateTour', {
            tour: newTour,
            gpx: this.fields.gpx,
            deleteGPX: this.deleteGPX,
            headerImage: this.headerImage,
            defaultHeaderImageURL: this.defaultHeaderImageURL,
          })
          .then(this.routeToDetail);
      } else {
        this.$store
          .dispatch('tour/createTour', {
            tourForm: this.fields,
            headerImage: this.headerImage,
            defaultHeaderImageURL: this.defaultHeaderImageURL,
            gpxURL: this.hasGPX && this.tour ? this.tour.gpxURL : null,
          })
          .then(this.routeToDetail);
      }
    },
    populate() {
      const tour = this.tour;
      if (!tour) return;
      const f = this.fields;

      (f.title = tour.title),
      (f.description = tour.description),
      (f.selectedSportType = tour.sportType),
      (f.selectedDifficultyLevel = tour.difficultyLevel),
      (f.startDateTime = this.mode.isDuplicate
        ? tomorrowIfSundayOrNextWeekend()
        : tour.startDateTime),
      (f.location = tour.location),
      (f.assemblyPoint = tour.assemblyPoint || ''),
      (f.distance = tour.distance || 0),
      (f.pace = tour.pace || 0),
      (f.altitude = tour.altitude || 0),
      (f.sourceLink = tour.sourceLink || ''),
      (f.routeStart = tour.routeStart),
      (f.legalDisclaimer = tour.legalDisclaimer || ''),
      (f.gpx = null);

      this.headerImagePreviewURL = tour.headerImageURL;
      this.hasGPX = tour.gpxURL != null;
    },
    routeToDetail(tourID: any) {
      const isMobile = window.screen.width < 480;
      const routeName = isMobile ? 'Mobile Tour Detail' : 'Tour Detail';
      router.replace({
        name: routeName,
        params: { tourId: tourID },
      });
    },
  },
});
